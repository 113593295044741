import React, {useContext,useState,useRef,useEffect} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';
import Login from '../../login/Login';
import "./styles.css";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignatureCanvas from 'react-signature-canvas'
import { BorderColor, Height } from '@material-ui/icons';

function Firma () {
   
 const sigCanvas = useRef({})

 const limpiar_firma = () => sigCanvas.current.clear()
 const [imageURL,setImageURL] = useState(null)
 const guardar_firma = () => setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))



  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            nit:'',
            ciudad:'',
            licencia:'',
            email:'',
            clave:'',
            pass:'',
            tipo_usuario:'empresa',         
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
      event.preventDefault();
      const data = await registerUser(state.userInfo);
      if(data.success){
          setState({
              ...initialState,
              successMsg:data.message,
          });
      }
      else{
          setState({
              ...state,
              successMsg:'',
              errorMsg:data.message
          });
      }
  }

  // On change the Input Value (name, email, password)
  const onChangeValue = (e) => {
      setState({
          ...state,
          userInfo:{
              ...state.userInfo,
              [e.target.name]:e.target.value
          }
      });
  }
  
  // Show Message on Success or Error
  let successMsg = '';
  let errorMsg = '';
  if(state.errorMsg){
      errorMsg = <div className="error-msg">{state.errorMsg}</div>;
  }
  if(state.successMsg){
      successMsg = <div className="success-msg">{state.successMsg}</div>;
  }

  
    
    if(isAuth && theUser.tipo_usuario =="empleado")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="theme-panel">
<a href="javascript:;" data-toggle="theme-panel-expand" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
<div class="theme-panel-content" data-scrollbar="true" data-height="100%">
<h5>Color Empresarial</h5>

<div class="theme-list">
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-red" data-theme-class="theme-red" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Red">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-pink" data-theme-class="theme-pink" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Pink">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-orange" data-theme-class="theme-orange" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Orange">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-yellow" data-theme-class="theme-yellow" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Yellow">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-lime" data-theme-class="theme-lime" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Lime">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-green" data-theme-class="theme-green" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Green">&nbsp;</a></div>
<div class="theme-list-item active"><a href="javascript:;" class="theme-list-link bg-teal" data-theme-class="" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-cyan" data-theme-class="theme-cyan" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cyan">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-blue" data-theme-class="theme-blue" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Blue">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-purple" data-theme-class="theme-purple" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Purple">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-indigo" data-theme-class="theme-indigo" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Indigo">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-black" data-theme-class="theme-gray-600" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Black">&nbsp;</a></div>
</div>

<div class="theme-panel-divider"></div>
<div class="row mt-10px">
<div class="col-8 control-label text-dark fw-bold">
<div>DÍA / NOCHE </div>
<div class="lh-14">

</div>
</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" value="1" />
<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
</div>
</div>
</div>
<div class="theme-panel-divider"></div>

{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Header Fixed</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-fixed" id="appHeaderFixed" value="1" checked />
<label class="form-check-label" for="appHeaderFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Color barra superior</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-inverse" id="appHeaderInverse" value="1" />
<label class="form-check-label" for="appHeaderInverse">&nbsp;</label>
</div>
</div>
</div>
{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menux</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-fixed" id="appSidebarFixed" value="1" checked />
<label class="form-check-label" for="appSidebarFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menu</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-grid" id="appSidebarGrid" value="1" />
<label class="form-check-label" for="appSidebarGrid">&nbsp;</label>
</div>
</div>
</div>
<div class="row mt-10px align-items-center">
<div class="col-md-8 control-label text-dark fw-bold">Color menu</div>
<div class="col-md-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" value="1" />
<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
</div>
</div>
</div>


</div>
</div>




{/*

<div class="row">


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-orange">
<div class="stats-icon"><i class="fa fa-users"></i></div>
<div class="stats-info">
<h4>EMPRESAS CREADAS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_creadas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-blue">
<div class="stats-icon"><i class="fa fa-desktop"></i></div>
<div class="stats-info">
<h4>LICENCIAS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Licencias" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-info">
<div class="stats-icon"><i class="fa fa-link"></i></div>
<div class="stats-info">
<h4>SOLICITUDES DEMO</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Solicitudes" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>EMPRESAS SUSPENDIDAS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

</div>

*/}

<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">CREAR EMPLEADO</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">
{/*div class="alert alert-muted">
Textual form controls—like <code>&lt;input&gt;</code>s, <code>&lt;textarea&gt;</code>s, <code>&lt;select&gt;</code>s are styled with the <code>.form-control</code> and <code>.form-select</code> class. Included are styles for general appearance, focus state, sizing, and more.
</div> */}
<form method="post" action="https://alira.ai-od.com/apiAlira/hoja_de_vida/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>



{/*imageURL 
?
 <img
//src={imageURL}
src="https://www.elcarrocolombiano.com/wp-content/uploads/2021/01/20210124-LOS-10-CARROS-MAS-VENDIDOS-DEL-MUNDO-EN-2020-01.jpg"
id="txtFoto" name="txtFoto"
alt= "mi firma"
style={{
  display:"block",
  margin:" 0 auto",
  //border:"1px solid black",
  width: "300px",
 // Height: "100px"
}}


/>
: null
*/}


{imageURL 
?
 <img
src={imageURL}
id="txtFoto" name="txtFoto"
alt= "mi firma"
style={{
  display:"block",
  margin:" 0 auto",
  //border:"1px solid black",
  width: "300px",
 // Height: "100px"
 
}}




/>
: null
}



              <input type="text" id="txtFoto" name="txtFoto"  value={imageURL}/>
            

     

{/*<div class="row mb-15px">

<div class="col-md-12">
<button className="btn btn-primary" type="submit">Crear Empresa</button>
</div> 



  
</div>  */}


{ errorMsg != "" || successMsg != "" ? <div class="alert alert-info alert-dismissible fade show"> <strong>{errorMsg}{successMsg}</strong></div> : '' }
<button className="btn btn-success w-100" type="submit" > ENVIAR FIRMA </button><br/><br/>
</form>

<Popup modal trigger={<button>Firma aqui</button>} position="right center"
closeOnDocumentClick={false}>
 {close => (
  <>
  <SignatureCanvas
  ref={sigCanvas}

  canvasProps={{className: "sigCanvas" }}
/>
<button onClick={close}>Cerrar</button>
<button onClick={limpiar_firma}>Borrar firma</button>
<button onClick={guardar_firma}>Guardar firma</button>
</>
 )}
  </Popup>
</div>



</div>

</div>




</div>


</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Firma
