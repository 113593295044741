import React,{useState} from 'react'
import ReactAudioPlayer from 'react-audio-player';



const Alarma_demo = () => {



  const [sound, setSound] = useState(true);



  return (
    <>


<ReactAudioPlayer
  src="assets\img\header\alarma_demo.mp3"
 autoPlay={sound}
 
 // onPlay={setSound}
  //controls
  allow
  loop="true"
/>

</>
  )
}

export default Alarma_demo