import React, {useContext,useState} from 'react';
import {MyContext} from '../../login/MyContext';
//import {Link} from 'react-router-dom';
import Login from '../../login/Login';

import Count from '../empresas/Count';

function Empresas () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

    const {toggleNav,registerUser} = useContext(MyContext);
    const initialState = {
        userInfo:{
            name:'',
            documento:'',
            ciudad:'',
            licencia:'',
            email:'',
            clave:'',
            pass:'',
            tipo_usuario:'empresa',    
            t_persona:'',
            tdoc:'',
            domicilio:'',
            nombre_r_legal:'',
            cedula_r_legal:'',
            contacto_empresarial:'',
            telefono_contacto:''    
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
      event.preventDefault();
      const data = await registerUser(state.userInfo);
      if(data.success){
          setState({
              ...initialState,
              successMsg:data.message,
          });
      }
      else{
          setState({
              ...state,
              successMsg:'',
              errorMsg:data.message
          });
      }
  }

  // On change the Input Value (name, email, password)
  const onChangeValue = (e) => {
      setState({
          ...state,
          userInfo:{
              ...state.userInfo,
              [e.target.name]:e.target.value
          }
      });
  }
  
  // Show Message on Success or Error
  let successMsg = '';
  let errorMsg = '';
  if(state.errorMsg){
      errorMsg = <div className="error-msg">{state.errorMsg}</div>;
  }
  if(state.successMsg){
      successMsg = <div className="success-msg">{state.successMsg}</div>;
  }

  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="theme-panel">
<a href="javascript:;" data-toggle="theme-panel-expand" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
<div class="theme-panel-content" data-scrollbar="true" data-height="100%">
<h5>Color Empresarial</h5>

<div class="theme-list">
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-red" data-theme-class="theme-red" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Red">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-pink" data-theme-class="theme-pink" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Pink">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-orange" data-theme-class="theme-orange" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Orange">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-yellow" data-theme-class="theme-yellow" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Yellow">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-lime" data-theme-class="theme-lime" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Lime">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-green" data-theme-class="theme-green" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Green">&nbsp;</a></div>
<div class="theme-list-item active"><a href="javascript:;" class="theme-list-link bg-teal" data-theme-class="" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-cyan" data-theme-class="theme-cyan" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cyan">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-blue" data-theme-class="theme-blue" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Blue">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-purple" data-theme-class="theme-purple" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Purple">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-indigo" data-theme-class="theme-indigo" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Indigo">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-black" data-theme-class="theme-gray-600" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Black">&nbsp;</a></div>
</div>

<div class="theme-panel-divider"></div>
<div class="row mt-10px">
<div class="col-8 control-label text-dark fw-bold">
<div>DÍA / NOCHE </div>
<div class="lh-14">

</div>
</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" value="1" />
<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
</div>
</div>
</div>
<div class="theme-panel-divider"></div>

{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Header Fixed</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-fixed" id="appHeaderFixed" value="1" checked />
<label class="form-check-label" for="appHeaderFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Color barra superior</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-inverse" id="appHeaderInverse" value="1" />
<label class="form-check-label" for="appHeaderInverse">&nbsp;</label>
</div>
</div>
</div>
{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menux</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-fixed" id="appSidebarFixed" value="1" checked />
<label class="form-check-label" for="appSidebarFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menu</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-grid" id="appSidebarGrid" value="1" />
<label class="form-check-label" for="appSidebarGrid">&nbsp;</label>
</div>
</div>
</div>
<div class="row mt-10px align-items-center">
<div class="col-md-8 control-label text-dark fw-bold">Color menu</div>
<div class="col-md-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" value="1" />
<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
</div>
</div>
</div>


</div>
</div>




<Count/>

{/*<div class="row">


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-orange">
<div class="stats-icon"><i class="fa fa-users"></i></div>
<div class="stats-info">
<h4>EMPRESAS CREADAS</h4>
<p>{global.empresas}</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_creadas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-blue">
<div class="stats-icon"><i class="fa fa-desktop"></i></div>
<div class="stats-info">
<h4>LICENCIAS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Licencias" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-info">
<div class="stats-icon"><i class="fa fa-link"></i></div>
<div class="stats-info">
<h4>SOLICITUDES DEMO</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Solicitudes" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>


<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>EMPRESAS SUSPENDIDAS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
</div>

</div> */}



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">

<h4 class="panel-title">CREAR EMPRESAS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body ">
{/*div class="alert alert-muted">
Textual form controls—like <code>&lt;input&gt;</code>s, <code>&lt;textarea&gt;</code>s, <code>&lt;select&gt;</code>s are styled with the <code>.form-control</code> and <code>.form-select</code> class. Included are styles for general appearance, focus state, sizing, and more.
</div> */}
<form onSubmit={submitForm}  class="">
 

<div class=" row mb-15px ">

<label class="form-label col-form-label col-md-2 ">Tipo de persona <b>*</b></label>

<div class="col-md-3 ">
<select class="form-select" name="t_persona"  value={state.userInfo.t_persona} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="Persona Natural">Persona Natural</option>
<option value="Persona Jurídica">Persona Jurídica</option>
</select>
<br/>
</div>

<div class="col-sm-7 ">


<div class="col-md-12 ">
<input type="text" name="name" value={state.userInfo.name} onChange={onChangeValue} class="form-control mb-5px" placeholder="Nombre" />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}

<label >Razón social o nombre <b>*</b></label>
</div>

</div>

</div>




<div class="row mb-15px">


<label class="form-label col-form-label col-md-2">Tipo de documento <b>*</b></label>
<div class="col-md-3">
<select class="form-select" name="tdoc"  value={state.userInfo.tdoc} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="NIT">NIT</option>
<option value="C.C">C.C</option>
<option value="C.E">C.E</option>
<option value="PEFF">PEFF</option>
</select>
<br/>
</div>


<div class="col-sm-7">

<div class="col-md-12">
<input type="number" name="documento" value={state.userInfo.documento} onChange={onChangeValue} class="form-control mb-5px" placeholder="0" />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}
<label>NIT / Documento <b>*</b></label>
</div>
</div>

</div>



<div class="row mb-15px">


<label class="form-label col-form-label col-md-2">Domicilio <b>*</b></label>
<div class="col-md-6">
<input type="text" class="form-control mb-5px" placeholder="Dirección" name="domicilio" value={state.userInfo.domicilio} onChange={onChangeValue} />
<br/>
</div>


<div class="col-sm-4 ">
<div class="col-md-12">


<input type="email" class="form-control mb-5px" placeholder="Email" name="email" value={state.userInfo.email} onChange={onChangeValue} />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}
<label>Correo de notificaciónes <b>*</b></label>
</div>
</div>


</div>


   



<div class="row mb-15px">
<label class="form-label col-form-label col-md-2">Ciudad <b>*</b></label>
<div class="col-md-5">
<select class="form-select" name="ciudad"  value={state.userInfo.ciudad} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="Ibague">Ibagué</option>
<option value="Espinal">Espinal</option>
</select>
<br/>
</div>

<div class="col-sm-5">

<div class="col-md-12">
<select  class="form-select" name="licencia" value={state.userInfo.licencia} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="Basico">Básico</option>
<option value="Premium">Premium</option>
<option value="Gold">Gold</option>
<option value="Demo">Demo</option>
</select>
<label>Licencia <b>*</b></label>
</div>
</div>


</div>



{/*<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Example textarea</label>
<div class="col-md-9">
<textarea class="form-control" rows="3"></textarea>
</div>
</div>*/}
<div class="row mb-15px">
<label class="form-label col-form-label col-md-2">Camara de comercio</label>
<div class="col-md-5">
<input class="form-control" type="file" id="formFile" />
<br/>
</div>

<div class="col-sm-5">
<div class="col-md-12">


<input type="nombre" class="form-control mb-5px" placeholder="Nombre" name="nombre_r_legal" value={state.userInfo.nombre_r_legal} onChange={onChangeValue} />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}
<label>Nombre representante legal <b>*</b></label>
</div>
</div>
</div>



<div class="row mb-15px">
<label class="form-label col-form-label col-md-2">Cedula representante legal <b>*</b></label>
<div class="col-md-4">
<input type="number" class="form-control mb-5px" placeholder="Cedula" name="cedula_r_legal" value={state.userInfo.cedula_r_legal} onChange={onChangeValue} />
<br/>
</div>

<div class="col-sm-3">
<div class="col-md-12">


<input type="text" class="form-control mb-5px" placeholder="Contacto" name="contacto_empresarial" value={state.userInfo.contacto_empresarial} onChange={onChangeValue} />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}
<label>Contacto empresarial <b>*</b></label>
<br/>
</div>

</div>

<div class="col-sm-3">
<div class="col-md-12">


<input type="number" class="form-control mb-5px" placeholder="Teléfono" name="telefono_contacto" value={state.userInfo.telefono_contacto} onChange={onChangeValue} />
{/*<small class="fs-12px text-gray-500-darker">We'll never share your email with anyone else.</small>*/}
<label>Teléfono de contacto <b>*</b></label>
</div>
</div>
</div>

<div class="row mb-15px">
<label class="form-label col-form-label col-md-2">Contraseña <b>*</b></label>
<div class="col-md-5">
<input type="password" class="form-control mb-5px" placeholder="*****" name="clave"  value={state.userInfo.clave} onChange={onChangeValue} />
<small class="fs-12px text-gray-500-darker">La contraseña debe llevar minimo 8 caracteres</small>
<br/>
</div>

<div class="col-sm-5">

<div class="col-md-12">
<input type="password" name="pass" onChange={onChangeValue} value={state.userInfo.pass} class="form-control mb-5px" placeholder="*****" id="inputSuccess"/>
<small class="fs-12px text-gray-500-darker">Repetir contraseña <b>*</b></small>
</div>
</div>
</div>

<div class="row mb-15px">

<div class="col-md-12">
<button className="btn btn-primary" type="submit">Crear Empresa</button>
</div>



  
</div>


{ errorMsg != "" || successMsg != "" ? <div class="alert alert-info alert-dismissible fade show"> <strong>{errorMsg}{successMsg}</strong></div> : '' }

</form>
</div>




{/*<div class="hljs-wrapper">
<pre><code class="html hljs language-xml"><span class="hljs-comment">&lt;!-- email --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">input</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"email"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> <span class="hljs-attr">placeholder</span>=<span class="hljs-string">"Enter email"</span> /&gt;</span>

<span class="hljs-comment">&lt;!-- select --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">select</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-select"</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">select</span>&gt;</span>

<span class="hljs-comment">&lt;!-- multiple select --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">select</span> <span class="hljs-attr">multiple</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-select"</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">select</span>&gt;</span>

<span class="hljs-comment">&lt;!-- textarea --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">textarea</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> <span class="hljs-attr">rows</span>=<span class="hljs-string">"3"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">textarea</span>&gt;</span>

<span class="hljs-comment">&lt;!-- file --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">input</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"file"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> /&gt;</span></code></pre>
</div>  */}

</div>

</div>




</div>


</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Empresas
